export enum ApiKey {
  threats = 'threats',
  detail = 'detail',
  users = 'users',
  sentinel = 'sentinel',
  comment = 'comment',
  message = 'message',
  insurance = 'insurance',
  request = 'request',
  internal_comment = 'comment',
  internal_message = 'message',
  list = 'list',
  admins = 'admins',
  plans = 'plans',
  documents = 'documents',
  incidentsHash = 'incidentshash',
  chainHash = 'chainhash',
  scoreCard = 'scoreCard',
  getCode = 'getCode',
  blog = 'blog',
  catagory = 'catagory',
  user_req = 'userRegistration',
  services = 'services',
  cove = 'cove',
  NSight = 'NSight',
  admin = 'admin',
  insuranceCompany = 'insurance-company',
  MISP = 'misp',
}
export const AdminRoles = {
  SUB: 'Sub',
  SUPER: 'Super',
}
export const CATEGORYLIST = {
  CYBER: 'Cyber',
  AI: 'AI',
}
export enum TabNavigation {
  Tab0 = 0,
  Tab1 = 1,
  Tab2 = 2,
  Tab3 = 3,
  Tab4 = 4,
  Tab5 = 5,
  Tab6 = 6,
}
export const defaultImage = {
  admin: '/images/img-placeholder.png',
}
export const defaultResendEmail = {
  open: false,
  email: null,
}
export const defaultNotificationWithError = {
  open: false,
  error: null,
}
export enum ValidationErrors {
  Message = 'Message is required',
  NAME = 'User name is required',
  PARAMETER_NAME = 'Parameter name is required',
  FIRST_NAME = 'First name is required',
  LAST_NAME = 'Last name is required',
  EMAIL_VALIDATION = 'Email must be a valid email address',
  EMAIL = 'Email is required',
  WEBSITE_URL = 'Website URL is required',
  INSURANCE_COMPANY = 'Carrier is  required',
  PORTFOLIO_NUMBER = 'Portfolio number is required',
  DISCRIPTION = 'Discription is required',
  ISSUE_NAME = 'Issue name is required',
  COMPANY = 'Company name is required',
  COMPANY_Name = 'Carrier name is required',
  COMPANY_NAME_VALIDATION = 'Carrier name must have only alphabet',
  COMPANY_URL = 'Company url is required',
  COMPANY_URL_VALIDATION = 'Please provide valid carrier url',
  ADDRESS = 'Address is required',
  PASSWORD = 'Password is required',
  MATCH_PASSWORD = 'Passwords must match',
  CONFIRM_PASSWORD = 'Confirm password is required',
  PASSWORD_VALIDATION = 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  PHONE = 'Phone number is required',
  PHONE_VALIDATION = 'Phone number start with + followed by the country code with 10 digits',
  ROLE = 'Role is required',
  DEFAULT = 'Failed to register, Please try again.',
  WHITE_SPACE = 'White space not allowed',
  NAMED = 'User name must have only alphabet shorter than or equal to 30 characters and only one space between word ',
  COMPANY_NAME = 'Company name must have alphabet shorter than or equal to 30 characters and must end on word and number',
  NO_WHITE_SPACE = 'No white space is allowed',
  LENGTH_VALIDATION = 'Characters need to be under 300 characters',
  CHANGES_SAVED = 'Changes saved',
  FIRST_NAME_VALIDATION = 'First name at least have 2, but not more than 10 charaters',
  LAST_NAME_VALIDATION = 'Last name at least have 2, but not more than 20 charaters',
  NAME_VALIDATION = 'Name at least have 2, but not more than 20 charaters',
  INSURANCE_PLAN = ' Policy is required',
  PAYMENT_TYPE = 'Please select payment method',
  PREMIUM = 'Premium amount is required',
  PREMIUM_VALIDATION = 'Premium amount should be greater than zero',
  WEBSITE_URL_VALIDATION = 'Please provide a valid website url',
  JUSTIFICATION = 'Justification is required',
  JUSTIFICATION_VALIDATION = 'Justification need to between 10 to 400 charaters',
  TITLE = 'Title is required',
  CATEGORY = 'Category is required',
  TAG = 'Tag is required',
  CONTENT = 'Content is required',
  IMAGE = 'Image is required',
  TEXTERROR = 'No spaces and number allowed',
  SERVICE_ERROR = 'Primary service (Sentinel One) must be selected in order to assign a policy. ',
  AFFILIATEID = 'Affiliate Id  is required',
  DESCRIPTION = 'Description is required',
  SENTINEL = 'Sentinel One  API Key is required',
  Patch = 'N-Sight-Patch Managment  API Key is required',
  SENTINELONE_URL = 'Sentinel One URL is required',
  SENTINELONE_URL_VALIDATION = 'Sentinel One URL must be a valid URL',
  ANNUAL_REVENUE_TYPEERROR = 'Annual Revenue must be a number',
  ANNUAL_REVENUE_POSITIVE = 'Annual Revenue must be greater than zero',
  REQUIRED = 'This field is required',
  DUPLICATE_FILES = 'Duplicate file  found. Please resolve before submitting.',
  FILE_ERROR = 'File size should not exceed 20mb',
  FILE_SUPPORT = 'Format  supported are jpeg, jpg, png',
}

export enum Payment {
  YES = 'yes',
  NO = 'no',
}

export enum Publish {
  PUBLISH = 'Publish',
  UN_PUBLISH = 'Unpublish',
  PUBLISHED = 'Published',
  UN_PUBLISHED = 'Unpublished',
  TRUE = 'true',
  FALSE = 'false',
}

export enum UserRole {
  SUPER = 'Super',
  SUB = 'Sub',
}

export enum CaseStatus {
  OPEN = 'Open',
  CLOSE = 'Close',
}

export enum AccordionList {
  HIDE = 'Hide',
  SHOW = 'Show',
}

export enum SideMenu {
  ADMINS_MANAGEMENT = 'Admins Management',
}

export enum IncidentEnums {
  RE_EVALUATION = 'Re-evaluation',
  CASE_CLOSED = 'CASE CLOSED',
  ACTION_COMPLETED_SUCCESSFULLY = 'ACTION COMPLETED SUCCESSFULLY',
  DOC_LABEL = 'Upload an Document',
  DOC_UPLOAD_LABEL = 'Upload an official file/doc supporting your action',
  BLOG_COVER_LABEL = 'Add Blog Cover Image',
  BLOG_UPLOAD_LABEL = 'Add Blog Image',
  USER_DOC_UPLOAD_LABEL = 'User Re-evalution file/doc supporting the claim amount',
  SENTINEL_VALIDATION = 'Sentinel key is required',
  SENTINEL_SUCCESS_MESSAGE = 'Sentinel key updated successfully',
  SENTINEL_ERROR_MESSAGE = 'Failed to update sentinel key',
  DISCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DISCRIPTION_DATE = 'Validation Date',
  INSURANCE_DETAIL = 'Insurance Detail',
  PENDING = 'Pending',
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISK_SCORE = 'Risk Score',
  USER = 'user',
  COMPANY = 'company',
  THREAT_INFO = 'Threat Info',
  THREAT_STATUS = 'threatStatus',
  THREAT_NAME = 'Threat Name',
  SUCCESS = 'success',
  DESCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DESCRIPTION_DATE = 'Validation Date',
  AGENT_DETECTION_INFO = 'Agent Detection Info',
  GROUP_ID = 'Group Id',
  GROUP_NAME = 'Group Name',
  AGENT_OS_NAME = 'Agent Os Name',
  CREATED_AT = 'Created At',
}

export const AlertKeyEnum = {
  CONFIDENCE_LEVEL: 'Confidence Level',
  CLASSIFICATION: 'Classification',
  PLAN_STATUS: 'Plan Status',
  STATUS: 'status',
  FA: '2FA',
  EDR: 'EDR',
  BACKUPS: 'Backups',
  PERSONAL_TRAINING: 'Personal Training',
  PATCH_MANAGEMENT: 'Patch Management',
  DEFAULT: 'Failed to register, Please try again.',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  DEVICE: 'Device Id',
}

export const RegPattern = {
  PASSWORD_VALIDATION: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
  // eslint-disable-next-line
  URL_VALIDATION: /^(https?|ftp):\/\/|www\.|[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  DISCRIPTION_VALIDATION: /^[a-zA-Z0-9 @.,!?()]{5,300}$/,
  FIRST_NAME_VALIDATION: /^[a-zA-Z]{2,10}$/,
  username: /^([a-zA-Z]+\s)*[a-zA-Z]{2,30}$/,
  // name: /^([a-zA-Z_])+([a-zA-Z0-9])+([\s][a-zA-Z0-9_]){2,30}$/,
  Companyname: /^([a-zA-Z_])+([a-zA-Z])+([\s][a-zA-Z]+)*$/,
  LAST_NAME_VALIDATION: /^[a-zA-Z]{2,20}$/,
  PHONE_VALIDATION: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[0-9]{7,15}/,
  NO_WHITE_SPACE: /^\S*$/,
  WHITE_SPACE: /^\S/,
  LENGTH_VALIDATION: /^[a-zA-Z0-9 @.,!?()]{0,300}$/,
  ZERO_VALIDATION: /[1-9][0-9]*$/,
  JUSTIFICATION_VALIDATION: /[a-zA-Z0-9@.,!?()_][a-zA-Z0-9@.,!?()_ ]*[a-zA-Z0-9@.,!?()_]{1,10}$/,
  SENTINELONE_URL: /^https:\/\/.*\.sentinelone\.net\/$/,
}
export const validNumber = {
  // eslint-disable-next-line
  pattern: /^[1-9][0-9]*$/,
}

export enum ClaimStatus {
  ALL = 'All',
  APPLICABLE = 'Applicable',
  NOT_APPLICABLE = 'Not Applicable',
  CLAIM_RAISED = 'Claim Raised',
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  RE_EVALUATE = 'Re-Evaluate',
  SERVICE_ACTIVATION_PENDING = 'Service Activation Pending',
  INCOMPLETE = 'Incomplete Application',
}

export enum IncidentStatus {
  ALL = 'All',
  INCIDENT_DETECTED = 'Incident Detected',
  INCIDENT_VERIFIED = 'Incident Verified',
  CLAIM_INITIATED = 'Claim Initiated',
  INSURANCE_CONFIRMATION = 'Insurance Confirmation',
  CLAIM_RESOLVED = 'Claim Resolved',
}

export enum STATUS_MAPPING {
  ACTIVE = 'Active',
  SUCCESS = 'success',
  PENDING = 'Pending',
  WARNING = 'warning',
  INACTIVE = 'Inactive',
  ERROR = 'error',
  COMING_SOON = 'coming soon',
  INFO = 'info',
  EXPIRED = 'Expired',
  ACTIVATED = 'activated',
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'Activate',
  COMPLETED = 'Completed',
}

export const validTitle = {
  // eslint-disable-next-line
  pattern: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
  message: 'Title name at least have 5, but not more than 50 characters and will not accept only spaces',
}
export enum UserStatus {
  ALL = 'All',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  BLOCKED = 'Blocked',
  ACCEPT = 'Accept',
  REJECT = 'Reject',
  DELETED = 'Deleted',
}
export enum AddUser {
  USERNAME = 'userName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNum',
  COMPANY_NAME = 'companyName',
  COMPANY_WEBSITE = 'companyWebsite',
  INSUERANCEC_COMPANY = 'insuranceCompany',
  INSUERANCE_ID = 'insuranceCompanyId',
  PLAN_ID = 'insurancePlanId',
  ERROR = 'Invalid affiliate id',
  AFFILIATE_ID_ERROR = 'Please enter the correct affiliate ID to continue.',
}

export enum CompanyStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  BLOCKED = 'Blocked',
}

export enum FileExtension {
  PDF = '.pdf',
  IMAGE = '.img',
  DOCX = '.docx',
}

export enum PlanStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  INACTIVE = 'Inactive',
  OVERDUE = 'Payment Overdue',
  CANCELLED = 'Cancelled',
  INCOMPLETE = 'Incomplete Application',
  SERVICE_ACTIVATION_PENDING = 'Service Activation Pending',
}

export const ThreatStatus = {
  All: 'All',
  INCIDENT_INITATED: 'Claim Initiated',
  INCIDENT_DETECTED: 'Incident Detected',
  INCIDENT_VERIFIED: 'Incident Verified',
  INCIDENT_CONFIRMATION: 'Insurance Confirmation',
  INCIDENT_RESOLVED: 'Claim Resolved',
  INCIDENT_BROADCASTED: 'Broadcasted',
  USER_CONFIRMATION: 'User Confirmed',
  BLOCK_CREATION: 'Block Created',
  CLAIM_RESOLVED: 'Claim Resolved',
}

export const keyEnum = {
  ENTER: 'Enter',
}

export const CommentType = {
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
}

export enum ChipColorEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const ApplicationEnum = {
  ALL: 'All',
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
}

export enum NotificationMessages {
  USER_SUCCESS = 'User added successfully',
  COMMENT_DELETE = 'Comment deleted successfully',
  COMMENT_EDIT = 'Comment edited successfully',
  BLOCK_ADMIN = 'Admin blocked successfully',
  UNBLOCK_ADMIN = 'Admin unblocked successfully',
  ADMIN_DELETED = 'Sub admin deleted successfully',
  SELF = 'You cannot perform action on yourself',
  POLICY = 'The selected carrier has no Active Policy',
  ROLE_SUCCESS = 'Admin role updated successfully',
  RESEND_EMAIL = 'Verification email resent successfully',
  RESENT_EMAIL = 'Email resent successfully',
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
  AFFILIATEID = 'Affiliate Id deleted sucessfully',
  AFFILIATEID_EDIT = 'Affiliate Id edited sucessfully',
  AFFILIATEID_ADD = 'Affiliate Id added sucessfully',
  RESEND_Payment = 'Payment reminder email resent successfully',
  COVE = 'Cove Connected Successfully',
  SENTINEL = 'SentinelOne Connected Successfully',
  PATCH = 'N-Sight Patch Connected Successfully',
  BLOCK_USER = 'User blocked successfully',
  UNBLOCK_USER = 'User unblocked successfully',
  DELETE_USER = 'User deleted successfully',
  DEACTIVATE_POLICY = 'Policy deactivated successfully',
  ACTIVATE_POLICY = 'Policy activated successfully',
  DEACTIVATE_SERVICE = 'Service deactivated successfully',
}

export enum ResendEmail {
  LABEL = 'Resend Email',
  TITLE = 'Resend Verification Email',
  CONTENT = 'Are you sure you want to resend verification email',
}
export enum ResendPaymentEmail {
  LABEL = 'Resend Email',
  TITLE = 'Resend Payment Due Reminder Email',
  CONTENT = 'Are you sure you want to resend payment due reminder email',
}

export const MESSAGE_ENUM = {
  VERIFICATION_LINK: 'To login to your account please verify your account. we have sent a verification link to your registered email',
}
export enum InsuranceCompanyLisitingStatus {
  ACTIVE = 'Activate',
  INACTIVE = 'Inactivate',
  ALL = 'All',
  STATUS = 'Status',
  ACTIVED = 'Activated',
  INACTIVED = 'DEACTIVATE',
  DEACTIVATE = 'Deactivate',
  DEACTIVATED = 'Deactivated',
}
export enum InsuranceCompanyAdmin {
  BLOCKED = 'Blocked',
}
export enum PolicyStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ALL = 'All',
}

export enum PaymentType {
  DIRECT_PAYMENT = 'Direct Payment',
  PAYMENT_USER = 'Stripe Payment By User',
  PAYMENT_ADMIN = 'Pay On User’s Behalf',
}

export enum UserList {
  BROADCASTED = 'Broadcasted',
}
export enum GetAQuoteStatus {
  UNRESOLVED = 'Unresolved',
  RESOLVED = 'Resolved',
}
export enum RiskScore {
  NOT_FOUND = 'not Found',
}
export enum MenuItem {
  ADD_BLOG = 'Add Blog',
  BLOG_LISTING = 'Blog Listing',
}
export enum ServicesEnum {
  EDR = 'Sentinel One',
  COVE_DATA = 'Cove Data Protection',
  N_SIGHT_PATCH = 'N-Sight-Patch Managment',
  N_SIGHT_AV = 'N-Sight AV (Bitdefender)',
  N_CENTERAL = 'N-Central',
}
export enum NSightPatchEnum {
  STATUSLABEL = 'statusLabel',
  PATCHURL = 'patchUrl',
  SEVERITYLABEL = 'severityLabel',
}
export enum StripeCardType {
  VISA = 'visa',
  MASTER = 'mastercard',
  AMEX = 'amex',
  DISCOVER = 'discover',
  JCB = 'jcb',
  DINERS = 'diners',
  ELO = 'elo',
}
export enum StripePaymentStatus {
  PAYING = 'Paying',
  PAY = 'Pay',
  CHANGE = 'Change',
  CHANGING = 'CHANGING',
}
export enum Mitigation_Status {
  NOT_MITIGATED = 'Not Mitigated',
  MITIGATED = 'mitigated',
}
export enum Environment_Status {
  DEVELOPMENT = 'development',
}

export enum OptionsLabel {
  NO_OPTIONS = 'No option available',
}
export enum SentinelOneEndpoint {
  ENDPOINT = 'web/api/v2.1/threats',
}
export enum DeactivateServicesEnum {
  COVE = 'Cove Data Protection',
  SENTINEL = 'Sentinel One',
  PATCH = 'N-Sight-Patch Management',
  AV = 'N-Sight AV (Bitdefender)',
}
export enum EvaluationStatus {
  EDR = 'EDR',
  COVE = 'Cove',
  NSIGHT = 'NSight',
}
export enum DeleteSubAdminEnum {
  ADMIN = 'Admin',
  MERCHANTS = 'Merchants',
}
